<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-message-text</v-icon> Solicitudes de liquidación</h1><br>
        <v-layout wrap>
          <v-flex xs4>
            <v-select
              label="Producto"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory();getKilos();getPrecio();"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <v-select v-if="producto!='Frutos secos'"
              label="Categoría"
              outlined
              :items="calidades"
              v-model="calidad"
              item-text="nombre"
              item-value="nombre"
              @change="getKilos();getPrecio();"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <v-select
              label="Tipo de liquidacion"
              v-model="tipoliquidacion"
              outlined
              :items="tiposliquidacion"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-text-field
              label="Inicio campaña"
              v-model="campanaInicio"
              outlined
              min=0
              step=1
              type="number"
              style="margin-right: 0;"
              @change="campanaInicioChange();"
            ></v-text-field>
          </v-flex>
          <span style="margin: 1% 1%"> / </span>
          <v-flex xs2>
            <v-text-field
              label="Fin campaña"
              v-model="campanaFin"
              outlined
              min=0
              step=1
              type="number"
              @change="campanaFinChange();"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Kilos"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="kilos"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="€ / Kg"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="precio"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Escandallo"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="escandallo"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" @click="save"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="eliminarPeticion(item)">
            mdi-delete
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Solicitudes',
  data () {
    return {
      producto: null,
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      calidades: [],
      calidad: null,
      kilos: '',
      precio: '',
      tipoliquidacion: 'K',
      tiposliquidacion: [{name:'Liquidación por kilos', value:'K'}, {name:'Anticipo de campaña', value:'A'}],
      campanaInicio: new Date().getFullYear(),
      campanaFin: new Date().getFullYear()+1,
      escandallo: '',
      tipoescandallo: 'euro',
      headersResumen: [
        {
          text: 'Fecha solicitud',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        { text: 'Producto', value: 'producto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Campaña', value: 'campana' },
        { text: 'Kilos', value: 'kilos' },
        { text: '€ / Kg', value: 'eurokilo' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.read();
  },
  methods:{
    clear(){
      this.producto = null;
      this.calidades = [];
      this.calidad = null;
      this.kilos = '';
      this.precio = '';
      this.tipoliquidacion = 'K';
      this.campanaInicio = new Date().getFullYear();
      this.campanaFin = new Date().getFullYear()+1;
      this.escandallo = '';
      this.tipoescandallo = 'euro';
    },
    async save(){
      if(this.producto!=null && this.calidad!=null && this.campanaInicio!='' && this.campanaFin!=''){
        if(this.precio!=''){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };
          const responseCompruebaLiquidacion = await genericReload(this.$store.state.auth, `liquidacion/?fechaInicial=${basics.getDate()}&fechaFinal=${basics.getDate()}&estado=espera`);
          if (responseCompruebaLiquidacion.status === 200) {
            const jsonCompruebaLiquidacion = await responseCompruebaLiquidacion.json();
            if(jsonCompruebaLiquidacion.body.length == 0){
              const dataLiquidacion = {
                fecha: basics.getDate(),
                campana: this.campanaInicio+"/"+this.campanaFin,
                producto: this.producto,
                calidad: this.calidad,
                tipo: this.tipoliquidacion,
                mostrar: (this.tipoliquidacion == "K") ? 0 : 1,
                kilos: this.kilos,
                precioKilo: this.precio,
                escandallo: this.escandallo,
                coop: 1,
                tipoescandallo: this.tipoescandallo,
                estado: 'espera'
              };
              const responseLiquidacion = await fetch(
                `${BASE_URL}/liquidacion`,
                {
                  method: 'POST',
                  headers: headers,
                  body: JSON.stringify(dataLiquidacion),
                }
              );
              switch (responseLiquidacion.status) {
                case 200:
                  const responseUltimaLiquidacion = await genericReload(this.$store.state.auth, `liquidacion/?fechaInicial=${basics.getDate()}&fechaFinal=${basics.getDate()}&estado=espera`);
                  const jsonUltimaLiquidacion = await responseUltimaLiquidacion.json();
                  const idLiquidacion = jsonUltimaLiquidacion.body[0].id
                  const data = {
                    idLiquidacion: idLiquidacion
                  };
                  const response = await fetch(
                    `${BASE_URL}/solicitud`,
                    {
                      method: 'POST',
                      headers: headers,
                      body: JSON.stringify(data),
                    }
                  );
                  switch (response.status) {
                    case 200:
                      this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
                      this.clear();
                      this.read();
                      break;
                    default:
                      this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
                      break;
                  }
                  break;
                default:
                  this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados. No se ha podido crear la liquidación en espera.", "Cerrar", "red");
                  break;
              }
            }else{
              this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados. Ya dispone de una solicitud realizada en el día de hoy, debe esperar a que esta se resuelva.", "Cerrar", "red");
            }
          }else{
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados. No se han podido comprobar las solicitudes anteriores.", "Cerrar", "red");
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No dispone de un precio de liquidación fijado para usted. Consulte con su almazara.", "Cerrar", "red");
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Debe de concretar que producto quiere liquidar, su categoría y a que campaña pertenece", "Cerrar", "red");
      }
    },
    async read(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `solicitud`);
      if (response.status === 200) {
        const json = await response.json();
        for(let i = 0; i<json.body.length; i++){
          const responseLiquidacion = await genericReload(this.$store.state.auth, `liquidacion/${json.body[i].idLiquidacion}`);
          if (responseLiquidacion.status === 200) {
            const jsonLiquidacion = await responseLiquidacion.json();
            let producto = (json.body[i].producto == "Cascara") ? "Frutos secos" : jsonLiquidacion.body[i].producto
            this.resumen.push({
              id: json.body[i].id, 
              fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"),
              producto: producto,
              idProducto: jsonLiquidacion.body[i].producto,
              calidad: json.body[i].calidad,
              kilos: json.body[i].kilos,
              eurokilo: json.body[i].precioKilo,
              campana: json.body[i].campana,
              idLiquidacion: json.body[i].idLiquidacion,
            });
          }
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de solicitudes", "Cerrar", "red");
      }
    },
    async eliminarPeticion(item){
      this.$refs.dialog.setNew("Eliminar solicitud de liquidación", "¿Estás seguro de querer eliminar esta solicitud?", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const data = {
            idLiquidacion: item.idLiquidacion
          };

          const response = await fetch(
            `${BASE_URL}/solicitud/${item.id}`,
            {
              method: 'DELETE',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Solicitud eliminada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Solicitud no eliminada", "Cerrar", "red");
              break;
          }
        }
      });
    },
    // productFilter(){
    //   if(this.producto!=null){
    //     this.resumen = this.resumen.filter((line) => line.idProducto === this.producto);
    //   }
    // },
    // categoryFilter(){
    //   if(this.calidad!=null){
    //     this.resumen = this.resumen.filter((line) => line.calidad === this.calidad);
    //   }
    // },
    // campanaFilter(){
    //   if(this.campanaInicio!=null && this.campanaFin!=null){
    //     this.resumen = this.resumen.filter((line) => line.campana == this.campanaInicio+"/"+this.campanaFin);
    //   }
    // },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
    campanaInicioChange(){
      this.campanaFin = parseInt(this.campanaInicio)+1;
      this.getKilos(); this.getPrecio();

    },
    campanaFinChange(){
      this.campanaInicio = parseInt(this.campanaFin)-1;
      this.getKilos(); this.getPrecio();
    },
    async getKilos(){
      if(this.calidad != null && this.campanaInicio != "" && this.campanaInicio != null && this.campanaFin != "" && this.campanaFin != null){
        this.kilos = 0;
        const response = await genericReload(this.$store.state.auth, `entrada/?fechaInicio=`+this.campanaInicio+`-05-01&fechaFin=`+this.campanaFin+`-04-31&tipo=`+this.producto);
        if (response.status === 200) {
          let json = await response.json();
          if(this.producto=="Aceituna"){
            json.body = json.body.filter((e) => e.calidad === this.calidad);
            for(let i = 0; i<json.body.length; i++){
              this.kilos += json.body[i].aceite;
            }
          }
          if(this.producto=="Cascara"){
            for(let i = 0; i<json.body.length; i++){
              this.kilos += json.body[i].pipa;
            }
          }
          if(this.producto=="Frutales"){
            json.body = json.body.filter((e) => e.categoria === this.calidad);
            for(let i = 0; i<json.body.length; i++){
              this.kilos += json.body[i].kilos;
            }
          }
        }
      }
    },
    async getPrecio(){
      if(this.calidad != null && this.campanaInicio != "" && this.campanaInicio != null && this.campanaFin != "" && this.campanaFin != null){
        this.kilos = 0;
        let categoria = this.calidades.find((e) => e.nombre === this.calidad).id;
        const response = await genericReload(this.$store.state.auth, `fijacionPrecio/?campana=`+this.campanaInicio+`/`+this.campanaFin+`&idCategoria=`+categoria);
        if (response.status === 200) {
          let json = await response.json();
          const productor = json.body.find((e) => e.tipo === "productor");
          if(productor != []){
            this.precio = productor.precio;
            this.escandallo = productor.escandallo;
          }else{
            const cp = json.body.find((e) => e.tipo === "cp");
            if(cp != []){
              this.precio = cp.precio;
              this.escandallo = cp.escandallo;
            }else{
              const general = json.body.find((e) => e.tipo === "general");
              if(general != []){
                this.precio = general.precio;
                this.escandallo = general.escandallo;
              }else{
                this.precio = '';
                this.escandallo = '';
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>